import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const UCLALandingPage = () => (
  <PhysicianLandingPage
    physician="UCLA"
    institution="UCLA"
    referralCode="UCLA"
    physicianURL="https://www.uclahealth.org/urology/"
  />
)

export default UCLALandingPage
